.agency-cats {
  border-bottom: 1px solid #333;
  margin-bottom: 30px;
  padding-bottom: 20px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .nav-link {
    min-width: 150px;
    text-align: center;
    border: 1px solid $border-color;
    color: #333;
    padding: 6px 15px;

    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &.active {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
  }

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    .nav-item {
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    .nav-link {
      display: block;
      width: 100%;
      min-width: unset;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
}

.agency-tabs {
  flex-direction: column;
  border-bottom: 1px solid #333;

  .nav-item {
    border-top: 1px solid #333;
  }

  .nav-link {
    align-items: center;
    color: #333;
    padding: 30px 15px;

    &:hover {
      color: #333;
    }

    .fa {
      font-size: 40px;
      color: $primary;
      margin-right: 15px;
    }

    &.active {
      background-color: $light;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 700;
  }

  &__addr {
    font-size: 16px;
  }

  @media (max-width: 1399px) {
    .nav-link {
      padding: 15px;
    }

    &__name {
      font-size: 16px;
    }

    &__addr {
      font-size: 14px;
    }
  }
}
