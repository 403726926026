.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 50px;
  }

  @media (min-width: 1500px) {
    img {
      height: 60px;
    }
  }
}

.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    & + & {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__inner {
      justify-content: flex-start;
    }

    &__btn {
      display: none;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}

.navigation {
  background-color: $light;

  &__inner {
    position: relative;
  }
}

.search {
  padding: 16px 20px;
  border-top: 1px solid #aaa;
  background-color: $light;

  .form-control {
    border: 1px solid #ccc;
    border-right: 0;
  }

  .input-group-text {
    background-color: $white;
    border: 1px solid #ccc;
    border-left: 0;
  }

  @media (min-width: 1200px) {
    width: 230px;
    background: 0;
    border: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    border: 1px solid #999;
    padding: 4px 0;

    .form-control {
      border-radius: 0;
      border: 0;
      border-right: 1px solid #999;
      padding: 0 16px;
      height: 24px;
    }

    .input-group-text {
      border-radius: 0;
      border: 0;
    }
  }
}

.lang {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 8px;
    }

    img {
      height: 18px;
    }
  }
}
