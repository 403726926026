.section {
  padding: 35px 0;

  &__title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
  }

  &__title-2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  &__info {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__content {
    font-size: 16px;
  }

  @media (max-width: 1499px) {
    &__title {
      font-size: 30px;
    }

    &__title-2 {
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
    }

    &__title-2 {
      font-size: 24px;
    }

    &__info {
      font-size: 14px;
    }

    &__content {
      font-size: 14px;
    }
  }
}
