$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;
      & > [class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px 15px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-gap: 16px 8px;
  }
}
