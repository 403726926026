.preview {
  &__thumbs {
    margin-top: 10px;
  }
}

.preview-slider {
  &__frame {
    @include frame("rect", 100%);
    background-color: $white;

    img {
      object-fit: contain;
    }
  }
}

.thumb-slider {
  @include slider-nav-2;
  position: relative;

  &__frame {
    @include frame("rect", 100%);

    img {
      object-fit: contain;
    }
  }
}
