.intro {
  padding: 60px 0;

  & + & {
    padding-top: 0;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 24px;
    text-align: center;
    margin-bottom: 35px;
  }

  &__body {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 0 auto;
    max-width: 150vh;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    padding: 150px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__card {
    width: 240px;
    border-radius: 120px;
    background-color: #ededed;
    padding: 15px;
    transition: 0.6s ease-out;

    &:nth-child(odd) {
      transform: translateY(100vh);
    }

    &:nth-child(even) {
      transform: translateY(-100vh);
    }
  }

  &.show &__card {
    transform: translateY(0) !important;
  }

  &__frame {
    @include frame("circle");
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #333;
  }

  &__text {
    text-align: center;
  }

  &__close {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: 10;
    background: 0;
    border: 0;
    transform: translateY(-100vh);
    transition: 0.6s ease-out;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 34px;
      border-top: 1px solid $border-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &.show &__close {
    transform: translateY(0);
  }

  &__play-btn {
    display: none;
  }

  @media (max-width: 1799px) {
    &__overlay {
      padding: 80px;
    }

    &__card {
      width: 220px;
    }
  }

  @media (max-width: 1499px) {
    &__title {
      font-size: 30px;
    }

    &__desc {
      font-size: 20px;
    }

    &__overlay {
      padding: 60px;
    }

    &__card {
      width: 180px;
      padding: 10px;
    }

    &__subtitle {
      font-size: 16px;
      margin: 10px 0;
      padding-bottom: 10px;
    }

    &__text {
      font-size: 13px;
    }
  }

  @media (max-width: 1199px) {
    padding: 40px 0;

    &__overlay {
      display: none;
    }

    &__play-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.2);
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      img {
        max-height: 40%;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.cats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-flow: row dense;

  &__col {
    display: flex;
    flex-direction: column;
    background-color: $white;
    position: relative;
    z-index: 1;
    padding: 20px;

    &:first-child {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 12px;
  }

  &__desc {
    text-align: center;
    margin-bottom: 12px;
  }

  &__frame {
    @include frame("square");
    width: 95px;
    max-width: 100%;
    margin: auto auto 0;

    img {
      object-fit: contain;
    }
  }

  &__col:nth-child(2),
  &__col:nth-child(5) {
    color: $white;
    background-color: #0076bc;

    img {
      filter: brightness(0) invert(1);
    }
  }

  &__col:nth-child(5) {
    background-color: #0080ff;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    &__col {
      display: flex;
      flex-direction: column;

      &:first-child {
        display: none;
      }

      &:nth-child(2),
      &:nth-child(5) {
        color: #333;
        background-color: $white;

        img {
          filter: brightness(1) invert(0);
        }
      }

      &:nth-child(5) {
        background-color: $white;
      }
    }

    &__frame {
      order: -1;
    }

    &__title {
      margin-top: 8px;
    }

    &__desc {
      line-height: 1.2;
      margin-bottom: 0;
    }
  }

  @media (max-width: 575px) {
    &__col {
      padding: 12px 10px;
    }

    &__title {
      font-size: 14px;
      line-height: 1.2;
    }

    &__frame {
      width: 80%;
    }
  }
}

.topnews {
  display: none;
  background-color: $primary;

  &.show {
    display: block;
  }

  &,
  a,
  a:hover {
    color: $white;
    text-decoration: none;
  }

  &__inner {
    position: relative;
    padding: 24px 30px;
  }

  &__content {
    max-width: 500px;
    position: relative;
    margin: 0 auto;
  }

  &__remove {
    display: flex;
    width: 30px;
    height: 30px;
    background: 0;
    border: 0;
    position: absolute;
    bottom: 24px;
    right: 0;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 34px;
      border-top: 1px solid $border-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__title {
    font-size: 24px;
    margin-right: 24px;
  }

  &__btn {
    display: inline-block;
    font-size: 12px;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media (max-width: 767px) {
    &__inner {
      padding: 10px 0;
    }

    &__content {
      padding: 0 30px 0 0;
    }

    &__label {
      font-size: 12px;
    }

    &__title {
      margin-right: 8px;
      font-size: 14px;
    }

    &__remove {
      bottom: 10px;
    }
  }
}

@keyframes loading-before {
  0% {
    transform: rotate(-135deg);
    z-index: 1;
  }

  50% {
    transform: rotate(45deg);
    z-index: 1;
  }

  50.001% {
    transform: rotate(45deg);
    z-index: 3;
  }

  100% {
    transform: rotate(45deg);
    z-index: 2;
  }
}

@keyframes loading-after {
  0% {
    transform: rotate(-135deg);
    border-color: transparent #ccc #ccc transparent;
  }

  50% {
    transform: rotate(-135deg);
    border-color: transparent #ccc #ccc transparent;
  }

  50.001% {
    transform: rotate(-135deg);
    border-color: $primary transparent transparent $primary;
  }

  100% {
    transform: rotate(45deg);
    border-color: $primary transparent transparent $primary;
  }
}

@keyframes loading-circle {
  0% {
    box-shadow: inset 0 0 0 3px #ccc;
  }

  99.9999% {
    box-shadow: inset 0 0 0 3px #ccc;
  }

  100% {
    box-shadow: inset 0 0 0 3px $primary;
  }
}

.loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: $white;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;

  &.hide {
    transform: translateX(-100vw);
    opacity: 0;
    transition: opacity 0.4s ease-out, transform 0s 0.4s;
  }

  &__circle {
    display: flex;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 3px #ccc;
    padding: 24px;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-color: transparent $primary $primary transparent;
      border-radius: 50%;
      transform: rotate(-135deg);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    &::before {
      animation: loading-before 1s linear infinite;
    }

    &::after {
      border-color: transparent #ccc #ccc transparent;
      animation: loading-after 1s linear infinite;
      z-index: 10;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 767px) {
    &__circle {
      width: 200px;
      height: 200px;
    }
  }
}

.insta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;

  &__col {
    @include frame("rect", 100%);
  }
}
