@mixin slider-nav-1 {
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    font-size: 18px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translateY(-50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  &:hover &__prev,
  &:hover &__next {
    background-color: rgba(#fff, 1);
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.5);
    }
  }
}

@mixin slider-nav-2 {
  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $border-color;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translate(-50%, -50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__next {
    left: 100%;
  }
}

@mixin partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    padding: 10px 5px;
    font-size: 18px;
    color: $secondary;
    font-size: 24px;
    color: $primary;
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  &__frame {
    display: block;
    position: relative;
    border: 1px solid $border-color;
    background-color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $border-color;
      font-size: 20px;
      line-height: 1;
    }

    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__pagination {
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $white;
      background: 0;
      opacity: 1;

      &:focus {
        outline: 0;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      &-active {
        background-color: $white;
      }
    }
  }

  @media (max-width: 1499px) {
    &__pagination {
      bottom: 20px;

      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &__pagination {
      bottom: 10px;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.product-slider {
  @include slider-nav-1();
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &__container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: auto;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.card-slider {
  position: relative;
  z-index: 1;
  padding-bottom: 20px;
  position: relative;

  &__pagination {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: #000;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &__frame {
    @include frame("rect", 100%);
  }

  &__title {
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    margin: 15px 0;
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #333;
    border-radius: 15px;
    color: #333;
    padding: 0 16px;
    margin-bottom: 2px;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
}
