.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .page-link {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    justify-content: center;
    align-items: center;
    padding: 2px;
    color: $gray-800;
  }
}
