@keyframes gradient-animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes menu-float-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-outt {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loading-rotate {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
