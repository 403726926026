.pd-info {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding-bottom: 16px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    flex: 1;
    margin-right: 20px;
    margin-bottom: 0;
  }

  &__info {
    & + & {
      margin-top: 16px;
    }

    ul,
    ol {
      padding-left: 17px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__label {
    display: inline-block;
    font-weight: 700;
    min-width: 130px;
    margin-right: 10px;
  }

  &__footer {
    border-top: 1px solid $border-color;
    margin-top: 24px;
    padding-top: 24px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 4px;
    background-color: $primary;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 10%);
    }
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    &__header {
      display: block;
    }

    &__title {
      margin-right: 0;
    }

    &__social {
      margin-top: 8px;
    }
  }
}

.detail-tabs {
  justify-content: center;
  align-items: flex-end;
  box-shadow: inset 0 -1px 0 $border-color;
  margin-bottom: 24px;

  .nav-item {
    &:not(:last-child) {
      margin-right: -1px;
    }
  }

  .nav-link {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 20px;
    min-width: 175px;
    text-align: center;
    color: #333;
    border: 1px solid $border-color;

    &:hover {
      color: #333;
      text-decoration: none;
    }

    &.active {
      font-weight: 700;
      line-height: 30px;
      border-bottom-color: $white;
    }
  }

  @media (max-width: 991px) {
    display: block;
    white-space: nowrap;
    overflow: auto;
    text-align: center;

    .nav-item {
      display: inline-block;
    }

    .nav-link {
      min-width: unset;
    }
  }
}

.product-detail {
  max-width: 800px;
  margin: 0 auto;
}
