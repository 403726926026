.faq-search {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .input-group {
    width: 410px;
    max-width: 100%;
  }

  .form-control {
    border-radius: 0;
    padding-left: 16px;
  }

  .input-group-text {
    border-radius: 0;
    background-color: $white;
  }
}

.faq-cat {
  border-bottom: 1px solid #999;

  &__header {
    display: flex;
    padding: 15px 30px;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    font-style: italic;
    background-color: rgba($primary, 0.05);
    border-top: 1px solid #999;

    &:hover {
      background-color: $light;
    }

    &::after {
      content: "\f107";
      font-family: fontawesome;
      display: flex;
      width: 60px;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      position: absolute;
      top: 0;
      right: 0;
    }

    // &.active {
    //   background-color: $light;
    // }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__body {
    display: none;
  }
}

.faq {
  border-top: 1px solid $border-color;
  font-size: 16px;
  line-height: 30px;

  &__header {
    display: flex;
    padding: 15px 0;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $light;
    }

    &::after {
      content: "\f107";
      font-family: fontawesome;
      display: flex;
      width: 60px;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      position: absolute;
      top: 0;
      right: 0;
    }

    // &.active {
    //   background-color: $light;
    // }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__answer {
    display: none;
  }

  &__body {
    display: flex;
    padding: 15px 0;
    border-top: 1px solid $border-color;
    background-color: $light;
  }

  &__label {
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $primary;
    font-size: 16px;
    border-radius: 50%;
    margin-right: 15px;

    &--yellow {
      background-color: #f88515;
    }
  }

  &__category {
    flex: 1;
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    margin-left: auto;
    padding-left: 150px;
  }

  &__header &__content {
    padding-right: 60px;
  }

  @media (max-width: 1199px) {
    font-size: 14px;
    line-height: 24px;

    &__label {
      width: 24px;
      height: 24px;
      font-size: 14px;
      margin-right: 12px;
    }

    &__content {
      flex: 0 0 calc(100% - 250px);
    }
  }

  @media (max-width: 991px) {
    &__header {
      display: block;
      padding: 8px 12px;
    }

    &__body {
      padding: 8px 12px;
    }

    &__category {
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      font-style: italic;
      color: #999;
      margin-bottom: 8px;
    }

    &__content {
      flex: 0 0 100%;
    }
  }
}
