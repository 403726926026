.news {
  text-align: center;

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__title {
    font-size: 24px;
    color: $primary;
    margin: 16px 0 12px;
  }

  &__desc {
    @include max-line(4);
  }

  &__link {
    display: inline-block;
    color: $primary;
    margin-top: 12px;
  }

  @media (max-width: 1499px) {
    &__title {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 14px;
      margin: 10px 0 8px;
    }

    &__desc {
      font-size: 12px;
    }

    &__link {
      font-size: 12px;
      margin-top: 8px;
    }
  }
}

.video {
  &__title {
    font-size: 14px;
    text-align: center;
    margin: 16px 0 0;
  }

  @media (max-width: 767px) {
    &__title {
      margin-top: 10px;
    }
  }
}

.product {
  &__frame {
    display: block;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 70%;
    }

    img {
      width: 95%;
      height: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.9);
      transition: 0.4s ease-out;
    }

    &:hover img {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0 0;
  }

  &__price {
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    color: $danger;
  }
}

.product-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;

  &__title {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  &__intro {
    margin-bottom: 16px;

    ul,
    ol {
      padding-left: 17px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__frame {
    display: flex;
    flex: 1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 38px;
    padding: 0 24px;
    border: 1px solid $border-color;
    border-radius: 20px;
    color: #333;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: $primary;
      border-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__intro {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &__footer {
      margin-top: 10px;
    }

    &__btn {
      line-height: 28px;
      font-size: 12px;
      padding: 0 16px;
    }
  }

  @media (max-width: 575px) {
    padding: 20px;

    &__title {
      font-size: 16px;
    }
  }
}

.product-3 {
  align-items: center;

  &__frame {
    @include frame("square");
    width: 180px;
    margin-right: 20px;

    img {
      object-fit: contain;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;

    a,
    a:hover {
      color: #333;
    }
  }

  &__price {
    font-size: 16px;
    font-weight: 700;
    color: $danger;
  }
}
