.footer {
  border-top: 1px solid $border-color;

  &__nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 12px 0;

    .menu-root > .menu-item > .menu-link {
      line-height: 65px;
    }
  }

  &__wrapper {
    background-color: #363636;
    padding: 40px 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: 7fr 4fr;
    grid-gap: 20px;
  }

  &__content {
    font-size: 14px;
    line-height: 26px;

    &,
    a,
    a:hover {
      color: $white;
    }
  }

  @media (max-width: 1199px) {
    &__nav-inner {
      display: block;
      padding: 15px 0;

      .menu-root {
        display: none;
      }
    }

    &__grid {
      grid-template-columns: 1fr;
    }

    &__wrapper {
      padding: 24px 0 60px;
    }
  }
}

.f-social {
  display: flex;

  &__item {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $primary;

    &:hover {
      color: $white;
      text-decoration: none;
      opacity: 0.5;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
