.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(.menu-sub) {
    border-bottom: 1px solid #aaa;
  }

  &-item {
    position: relative;
    border-top: 1px solid #aaa;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-left: 1px solid #aaa;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &::before {
      content: "\f105";
      font-family: "fontawesome";
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      color: #666;
      font-size: 18px;
      border-radius: 40px;
      // background-color: rgba(#000, 0.1);
      transition: 0.3s ease-out;
    }

    &.active {
      // background-color: rgba(#15191e, 0.08);

      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &-sub {
    display: none;
    // background-color: $gray-100;
    background-color: rgba(#15191e, 0.08);

    .menu-link {
      padding-left: 35px;
      position: relative;
    }
  }

  &-sub-2 {
    // background-color: $gray-200;

    .menu-link {
      padding-left: 45px;
    }
  }

  &-sub-3 {
    // background-color: $gray-300;

    .menu-link {
      padding-left: 55px;
    }
  }

  &-root > &-item > &-link {
    text-transform: uppercase;
  }

  &-gallery {
    display: none;
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    &:not(.menu-sub) {
      border: 0;
    }

    &-item {
      border: 0;
    }

    // menu root
    &-root {
      display: flex;
      border: 0;
    }

    &-root > &-item {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &-root > &-item > &-link {
      padding: 0;
      line-height: 80px;

      &.active {
        // color: $primary;
        box-shadow: inset 0 -3px 0 #111;
      }
    }

    &-root > &-item:hover > &-link {
      // color: $primary;
      box-shadow: inset 0 -3px 0 #111;
    }

    &-root > &-item > &-toggle {
      display: none;
    }

    &-root > &-item-group > &-link,
    &-root > &-item-mega > &-link {
      &::after {
        content: "\f0d7";
        font-family: fontawesome;
        margin-left: 4px;
      }
    }

    &-root > &-item-group &-item:hover > &-link {
      background-color: $light;
    }

    &-root > &-item-group &-sub {
      min-width: 200px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      border-radius: 4px;
      transform-origin: 0 4px;
      background-color: $white;

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root > &-item-group > &-sub {
      transform-origin: 0 0;
      top: 100%;
      left: 0;
    }

    &-root > &-item-group &-sub &-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }

    &-root > &-item-group &-sub &-toggle {
      width: 30px;
      border-left: 0;
    }

    &-root > &-item-group &-sub &-link {
      padding-left: 20px;
    }

    &-root > &-item-group &-item-group > &-link {
      padding-right: 30px;
    }

    &-root > &-item-group:hover > &-sub,
    &-root > &-item-group &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    // menu mega
    &-item-mega {
      position: static;
    }

    &-item-mega:hover &-mega {
      display: grid;
      animation: fade-in 0.2s ease-out;
    }

    &-mega {
      display: none;
      grid-template-columns: 5fr 7fr;
      width: 100%;
      background-color: $white;
      z-index: 50;
      padding: 20px 0;
      transform-origin: top left;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;

      &.show {
        display: grid;
        animation: fade-in 0.2s ease-out;
      }

      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100%;
        background-color: $white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        @include shadow("xs");
      }
    }

    &-mega &-sub {
      display: block;
      position: static;
      box-shadow: unset;
      border-radius: 0;
      background: 0;
      min-width: unset;
      top: auto;
      left: auto;
    }

    &-mega &-item {
      margin: 0;
      position: relative;
    }

    &-mega &-link {
      display: inline-block;
      text-transform: uppercase;
      font-size: 16px;
      padding: 5px 20px;
      line-height: 30px;
    }

    &-mega &-toggle {
      border-left: 0;
      pointer-events: none;
    }

    &-mega &-item:hover > &-link {
      background-color: $light;
    }

    &-mega > &-sub > &-item {
      max-width: 300px;
    }

    &-mega &-sub &-sub {
      display: none;
      min-width: 240px;
      background-color: $white;
      @include shadow("");
      transform-origin: 0 0;
      position: absolute;
      top: 0;
      left: 100%;
    }

    &-mega &-item:hover > &-sub {
      display: block;
      animation: menu-float-show 0.3s ease-in-out;
    }

    &-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1300px) {
    &-full-width > &-item:last-child > &-sub {
      left: auto;
      right: 0;
      transform-origin: 100% 0;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1600px) {
    &-full-width > &-item:last-child > &-sub &-sub {
      left: auto;
      right: calc(100% + 4px);
      transform-origin: 100% 4px;
    }
  }

  @media (min-width: 1500px) {
    &-root > &-item > &-link {
      font-size: 16px;
    }
  }
}
